@import "./variables.scss";

h2 {
  color: $secondary-color;
  font-weight: 500;
  opacity: 0.8;
  margin-top: 40px;
  margin-bottom: 20px;
}

.container-row {
  flex: 1;
  flex-direction: row;
}

footer {
  background-color: $secondary-color;
  color: white;
}

.link-primary {
  color: $primary-color !important;
  &:hover {
    color: white !important;
    font-weight: bold !important;
    text-decoration: none !important;
  }
}

.bgd-primary {
  background-color: $primary-color;
}

.bgd-secondary {
  background-color: $secondary-color !important;
  color: $primary-color;
}

.card {
  border-radius: 15px !important;
  border: 0 !important;
}

.input-text {
  display: block !important;
  margin: 0;
  color: inherit !important;
  width: 100% !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  border: none !important;
  border-radius: 0.4rem !important;
  transition: box-shadow 0.5s !important;
}

.input-text::placeholder {
  color: #b0bec5 !important;
}

.input-text:focus {
  outline: none !important;
  box-shadow: 0.2rem 0.8rem 1.6rem $primary-color !important;
}

.online-cprimary {
  color: $primary-color !important;
  border-color: $primary-color !important;
  &:hover {
    background-color: $primary-color !important;
    color: $secondary-color !important;
  }
}

.online-csecondary {
  color: $secondary-color !important;
  border-color: $secondary-color !important;
  &:hover {
    background-color: $secondary-color !important;
    color: $primary-color !important;
    font-weight: bold !important;
  }
}

.msg-error {
  color: red;
  background-color: rgba($color: red, $alpha: 0.35);
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.card-info {
  border-bottom: 1px solid #b0bec5;
  margin-top: 10px;
  .title {
    opacity: 0.7;
  }
  .info {
    color: "black";
    font-weight: bold;
  }
}

.badge-info {
  margin-left: 7px;
  margin-right: 7px;
  background-color: $secondary-color;
  color: $primary-color;
  font-weight: 600;
  font-size: 22px;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
}
